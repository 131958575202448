const burger = document.getElementById('a-burger');
const mainMenu = document.getElementById('main-menu');


burger.addEventListener('click', ()=>{
    burger.classList.toggle('a-burger-active');
    mainMenu.classList.toggle('a-collapse-menu-open');
});




gsap.registerPlugin(ScrollTrigger);

const scrollFadeUp = document.querySelectorAll('.scroll-fadeup');
scrollFadeUp.forEach(function(item){
    gsap.fromTo( item, { y: 100, opacity: 0}, {
        y: 0,
        opacity: 1,
        ease: "ease-in-out",
        scrollTrigger: {
            start: "top bottom",
            end: "top center",
            scrub: true,
            trigger: item 
        }
    });
})

const scrollSlideUp = document.querySelectorAll('.scroll-slideup');
scrollSlideUp.forEach(function(item){
    gsap.fromTo( item, { y: 100}, {
        y: 0,
        ease: "ease-in-out",
        scrollTrigger: {
            start: "top bottom",
            end: "top center",
            scrub: true,
            trigger: item 
        }
    });
})

const slightRotate = document.querySelectorAll('.scroll-slightRotate');
slightRotate.forEach(function(item){
    gsap.fromTo( item, { rotation: 15}, {
        rotation: 2,
        ease: "ease-in-out",
        scrollTrigger: {
            start: "top bottom",
            end: "top center",
            scrub: true,
            trigger: item 
        }
    });
})
const scrollRotate = document.querySelectorAll('.scroll-rotate');
scrollRotate.forEach(function(item){
    gsap.fromTo( item, { rotation: 30}, {
        rotation: 0,
        ease: "ease-in-out",
        scrollTrigger: {
            start: "top bottom",
            end: "top center",
            scrub: true,
            trigger: item 
        }
    });
})

const scrollFadeLeft = document.querySelectorAll('.scroll-fadeleft');
scrollFadeLeft.forEach(function(item){
    gsap.fromTo( item, { x: 100, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: "ease-in-out",
        scrollTrigger: {
            start: "top bottom",
            end: "top center",
            scrub: true,
            trigger: item 
        }
    });
})
